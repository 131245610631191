$metric: 0.5rem;
$white: rgb(255, 255, 255);
$grey: rgb(241, 241, 241);
$darkgrey: #e0e0e0;
$shadow: rgb(150, 150, 150);
$red: rgb(255, 89, 89);
$green: rgb(0, 219, 18);
$font_size: 1rem;
$border_size: 0.15rem;
$total_height: 100vh;
$transparentWhite: rgba(255, 255, 255, 0.5);
$gold: rgb(218, 185, 0);
$silver: #c0c0c0ff;
$bronze: #cd7f32ff;
$blue: #2353fe;
$black: rgb(17, 17, 17);
$transblack: rgba(0, 0, 0, 0.493);
$purple: #8e7eff;
$yellow: rgb(255, 217, 0);
$lightgrey: #cccccc;
$darkshadow: #000000c9;
$greyText: rgb(69, 69, 69);

@font-face {
  font-family: OpenSans;
  src: url("./assets/font/OpenSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: OpenSansB;
  src: url("./assets/font/OpenSans-ExtraBold.ttf") format("truetype");
}

body {
  text-align: center;
  color: $white;
  background-color: $purple;
  font-family: OpenSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  overflow: none;
}

h1,
h2,
h3 {
  font-family: OpenSansB;
  margin: 0;
  font-weight: normal;
}

a {
  color: $white;
  // font-weight: bold;
  text-decoration: none;
  font-family: OpenSansB;
}

button {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: OpenSansB;
  // font-family: NewF;
  font-weight: normal;
  cursor: pointer;
  background-color: $white;
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none;
  border: none;
  padding: $metric 2 * $metric;
  font-size: $font_size;
  border-radius: $font_size * 2;
  // box-shadow: 0 $border_size/2 $black;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  transition: all 0.05s linear;

  box-shadow: 0 $border_size $black;
  transform: translateY(-1 * $border_size/2);
  img {
    height: 1 * $font_size !important;
    background-color: $white;
    padding: $metric/2;
    border-radius: $font_size * 2;
    margin-right: $metric;
  }

  h3{
    
  }
}

input,
textarea {
  font-family: OpenSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: normal;
  cursor: pointer;
  background-color: $white;
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none;
  border: none;
  padding: $metric 2 * $metric;
  font-size: $font_size;
  border-radius: $font_size * 1;
  border: 0.1rem $darkgrey solid;
  margin: auto;
}

// button:hover {
//   box-shadow: 0 $border_size $black;
//   transform: translateY(-1 * $border_size/2);
// }

.pageNotFound{
  background-color: $white;
  padding-top: 11*$metric;
  color: $black;

  h1{
    margin-bottom: 4*$metric;
  }

  button{
    color: $white;
    background-color: $purple;
    margin: auto;
  }
}

button:active {
  box-shadow: 0 $border_size/2 $black;
  transform: translateY(0);
}

.burgerMenu {
  display: none;
}
.hamburger-react {
  display: none;
  margin-left: auto;
}

.header {
  z-index: 1000;
  background-color: $white;
  color: $black;
  padding: $metric;
  position: fixed;
  width: calc(100% - 1rem);
  border-bottom: $darkgrey $border_size solid;
  .inner {
    max-width: $font_size * 65;
    margin: auto;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    a {
      color: $black;
      cursor: pointer;
    }
    align-items: center;
    height: 2.5 * $font_size;
    .left {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: $metric * 2;
      img {
        height: 2.5 * $font_size;
        margin-right: $metric;
      }
      h1 {
        // font-family: NewF;
        font-weight: normal;
        margin: 0;
      }
    }

    .right {
      display: flex;
      flex-direction: row;
      float: right;
      flex: 1;

      align-items: center;

      .home {
        margin-left: auto;
      }
      .login {
        margin-left: 4 * $font_size;
      }

      button {
        margin-left: $font_size * 2.5;
        background-color: $purple;
        color: $white;
        // font-weight: bold;
        cursor: pointer;
        padding: $metric/1.4 $metric * 1.5;
        img {
          background-color: $white;
          padding: $metric/2;
          border-radius: $font_size * 2;
          margin-right: $metric;
        }
      }

      a {
        margin-left: $font_size * 2.5;
      }
    }
  }
}

.landing {
  text-align: center;
  // background-image: url(./assets/landing/background.png);
  background-repeat: no-repeat;
  background-size: 100vw;
  background-color: $purple;

  .contactUs {
    margin-top: $metric*2;
    color: $greyText;
    cursor: pointer;
    // text-decoration: underline;
  }
  .yc {
    display: flex;
    align-items: center;
    text-align: left;
    width: fit-content;
    white-space: nowrap;

    img {
      height: $font_size * 2.5 !important;
      margin-left: 1 * $metric;
      cursor: pointer;
    }
  }

  .video{
    
    margin-top: 2rem;

    div{
        width: 85vw !important;
      max-width: 45rem !important;
      height: auto !important;
      margin: auto;
    }
    video{
      
      border-radius: 1rem;
    }
  }
  .top {
    padding-top: 13.5 * $metric;
    padding-bottom: 4 * $metric;
    background-color: $white;
    // height: calc(100vh - 3.5rem);
    // min-height: 80 * $metric;
    display: flex;
    align-items: center;
    flex-direction: row;
    img {
      height: $metric * 20;
      width: auto;
      margin-bottom: 0;
    }
    .mid {
      margin: auto;
    }

    h1 {
      font-size: 3 * $font_size;
      // font-weight: bolder;
      padding: 2 * $metric $metric;
      // padding-top: 15 * $metric;
      // background-color: $white;
      color: $purple;
    }
    h2 {
      color: $greyText;
      font-size: $font_size * 1.5;
      // background-color: $white;
    }
    p {
      color: $greyText;
      margin-bottom: $metric/2;
      padding-top: $metric * 0;
      font-size: $font_size * 1.1;
      margin: 2 * $metric auto $metric/2;
    }
    svg {
      height: $font_size * 1.1;
      color: $greyText;
      padding-bottom: $metric * 2;
    }
    button {
      margin: auto;
      margin-top: 3 * $metric;
      font-size: 1.25 * $font_size;
      background-color: $purple;
      color: $white;
    }
    h3 {
      font-size: 1.25 * $font_size;
    }

    .slackLanding {
      margin-top: $metric * 2;
      background-color: rgb(231, 231, 231);
      color: black;
      font-size: 1 * $font_size !important;
    }
  }

  .images {
    padding: 10 * $metric 2 * $metric 0;
    padding-bottom: 10*$metric;
    h2 {
      width: fit-content;
      padding: 0 $metric;
      margin: auto;
      font-size: 1.75 * $font_size;
    }
  }

  .podium {
    // margin: 2 * $metric;
    width: 85vw;
    max-width: $font_size * 45;
    margin-bottom: 8 * $metric;
    margin-top: 4 * $metric;
    border-radius: 1.5 * $metric;
    background-color: $white;
  }
  .addB {
    // border: $white $border_size * 0.9 solid;
  }

  .features {
    max-width: $metric * 110;
    margin: auto;
  }

  .featuresOuter {
    background-color: $white;
    padding: 10 * $metric 0;
    h2 {
      font-size: 2 * $font_size;
      color: $greyText;
      margin-bottom: 2 * $metric;
    }
  }

  .featureContainer {
    background-color: $white;
    color: $greyText;
    width: 40%;
    height: 80 * $metric;
    overflow: hidden;
    max-width: $metric * 60;
    padding: $metric * 4;
    margin: $metric;
    border-radius: $metric * 3;
    display: inline-block;
    border: $darkgrey $border_size solid;
    h2 {
      color: $purple;
      font-size: 2 * $font_size;
    }
    ul {
      text-align: left;
      padding-left: 4 * $metric;
    }
    p {
      text-align: left;
    }
    a {
      color: $purple;
      cursor: pointer;
    }
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      margin-bottom: $metric * 2;
      border-radius: 1 * $metric;
    }
    button {
      // font-family: OpenSans;
      color: $white;
      background-color: $purple;
      padding: $metric * 0.6 $metric;
      padding-right: $metric * 1.5;
      font-size: $font_size * 0.9;
      margin-top: $metric;
      img {
        height: $font_size * 1.4 !important;
        width: auto;
        border-radius: $font_size;
        margin-bottom: 0;
        margin-right: $metric;
        background-color: #ffffff;
        padding: $font_size * 0.1 $font_size * 0.2;
      }
    }
  }

  .gamesContainer {
    margin: 2 * $metric 0;
    // height: 20 * $metric;
    // position: relative;
    // overflow: hidden;
    // .banner {
    //   position: absolute;
    //   top: 0px;
    //   left: 0px;
    //   overflow: hidden;
    //   // white-space: nowrap;
    //   animation: bannermove 10s linear infinite;
    //   // width: 100%;
    // }

    // @keyframes bannermove {
    //   0% {
    //     transform: translate(0, 0);
    //   }
    //   100% {
    //     transform: translate(-50%, 0);
    //   }
    // }
  }

  .games {
    // background-color: $white;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    padding: $metric * 10 $metric * 2;

    .buttons{
      display: flex;
      width: fit-content;
      align-items: center;
      flex-direction: row;
      margin: $metric auto;
      *{
        margin: 0 $metric;
      }
    }

    .gLogo{
      height: 5*$metric;
    }

    .gIframe{
      height: 70vh;
      width: 100%;
      border: none;
    }

    h2 {
      font-size: 2 * $font-size;
      // color: $purple;
    }
    p {
      font-size: $font_size * 1.25;
      margin: 0;
    }
  }

  .gameContainer {
    display: inline-block;
    margin: $metric;

    .Candy {
      background-position: left;
      background-color: #1b00ff26;
      background-blend-mode: overlay;
    }

    .Hoop {
      background-position: left;
    }
  }
  .game {
    width: 40 * $metric;
    height: 12 * $metric;
    padding: $metric;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4 * $metric;
    border: $border_size * 2 $white solid;
    img {
      height: 100%;
      width: 15 * $metric;
      object-fit: contain;
      margin-right: $metric;
    }
    span {
      text-align: left;
      font-family: OpenSansB;
      text-shadow: #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px,
        #000 0px 0px 1px, #000 0px 0px 1px, #000 0px 0px 1px;
      -webkit-font-smoothing: antialiased;
    }
  }

  .security {
    background-color: $white;
    padding: 6 * $metric 2 * $metric;
    padding-bottom: 8 * $metric;
    color: $greyText;
    vertical-align: top;
    h2 {
      font-size: 2 * $font_size;
      margin-top: 0;
    }
    .title {
      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: fit-content;
        margin: auto;

        svg {
          margin-right: $metric;
          height: 1.5 * $font_size;
        }
        h2 {
          font-size: 1.5 * $font_size;
          width: fit-content;
        }
      }
    }

    .item {
      width: calc(100vw - 6rem);
      max-width: $font_size * 30;
      border-radius: 3 * $metric;
      border: $darkgrey $border_size solid;
      padding: 2 * $metric;
      display: inline-block;
      margin: $metric;
      height: 23 * $metric;
      div {
        display: flex;
        flex-direction: row;
        // align-items: center;
        img {
          margin-top: $metric * 2;
          width: 40%;
          height: 10 * $metric;
          object-fit: contain;
        }
        p {
          text-align: left;
          padding: 2 * $metric;
          height: 15 * $metric;
          font-size: 1.1 * $font_size;
          margin-left: $metric * 2;
        }
        a {
          color: $purple;
        }
      }
    }
  }

  .feedback {
    padding: 6 * $metric 0;
    text-align: center;
    .container {
      padding: 3 * $metric;
      border-radius: 2 * $metric;
      border: $white $border_size solid;
      max-width: 40 * $metric;
      width: calc(33vw - 5rem);
      display: inline-block;
      margin: $metric;
      .quote {
        font-size: 1.5 * $font_size;
        margin: 0;
        margin-bottom: $metric * 4;
        text-align: left;
      }
      .person {
        display: flex;
        flex-direction: row;
        margin: auto;
        text-align: left;
        align-items: center;
        img {
          width: 6 * $metric;
          height: 6 * $metric;
          border-radius: 3 * $metric;
          margin-right: $metric;
        }
        div {
          h3,
          p {
            margin: 0;
          }
        }
      }
    }
  }

  .connections {
    background-color: $white;
    color: $greyText;
    padding: 10 * $metric 2 * $metric 10 * $metric;
    padding-top: $metric;
    h2,
    h3 {
      margin: $metric;
    }

    h2 {
      font-size: 2 * $font_size;
      margin-bottom: 4 * $metric;
    }

    h3 {
      font-weight: normal;
      font-family: "OpenSans";
      margin-left: 0;
    }

    a {
      color: $purple;
      cursor: pointer;
    }

    .card {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: $border_size $darkgrey solid;
      border-radius: 2 * $metric;
      max-width: $metric * 70;
      padding: $metric * 2;
      margin: $metric;
      margin: auto;
      img {
        height: 10 * $metric;
        margin-right: $metric * 2;
      }
      .content {
        text-align: left;
      }
    }
  }

  .freeTrial {
    padding: 10 * $metric 2 * $metric;
    padding-top: 10 * $metric;
    h2 {
      font-size: 2.5 * $font_size;
    }
    button {
      font-size: 1.5 * $font_size;
      margin: auto;
      margin-top: 3 * $metric;
      color: $greyText;
    }
  }
}

.footerOuter {
  background-color: $white;

  .footer {
    margin: auto;
    width: fit-content;
    p {
      margin: auto;
    }
    a {
      color: #3a3a3a;
      font-family: "OpenSans";
      margin-right: 0.4rem;
      cursor: pointer;
      h3{
        text-decoration: underline;
        color: $purple;
      }
    }

    .helpButton {
      margin-top: 2 * $metric;
      cursor: pointer;
    }
    button {
      margin: auto;
      color: $white;
      background-color: $purple;
    }
    color: $greyText;
    padding: 6 * $metric 2 * $metric;
    // display: flex;
    // flex-direction: row;
    // align-items: center;

    .top {
      color: $greyText;
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: $white;
      margin-bottom: $metric * 6;
    }

    .contact {
      font-size: 1.1 * $font_size;
      b {
        // color: $purple;
        // font-family: 'OpenSansB';
      }
      margin-bottom: $metric * 3;
      // text-align: left;
    }

    .logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: fit-content;
      margin: auto;
      margin-bottom: $metric * 4;
      img {
        height: 4 * $metric;
        margin-right: $metric;
      }
      h3 {
        font-size: 2 * $font_size;
      }
    }
  }
}

.pricing {
  color: $greyText;

  h1 {
    font-size: 1.5 * $font_size;
    margin-top: $metric * 4;
    color: $white;
    // font-family: OpenSans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    //   Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }

  .starting {
    h2 {
      font-size: 3 * $font_size;
      color: $purple;
      span {
        font-size: 1 * $font_size;
      }
    }
  }

  h3 {
    font-size: 2.5 * $font_size;
    margin: $metric * 6 $metric;
    color: $white;
  }

  button {
    background-color: $purple;
    color: $white;
    font-size: $font_size * 1.5;
    margin: 3 * $metric auto;
  }
  p {
    margin: 0;
  }
  .underButton {
    margin-bottom: $metric;
  }
  .or {
    margin-top: $metric;
  }

  .oneplpus {
    cursor: pointer;
  }

  // .sliderContainer{

  // }

  .pricingT {
    padding: 5 * $metric 0 10 * $metric;
    background-color: $white;
    color: $greyText;
    h3 {
      font-size: 2 * $font_size;
      margin-bottom: 4 * $metric;
      color: $greyText;
    }

    button {
      // color: $greyText;
      // background-color: $white;
    }
  }

  .topP {
    padding: 8 * $metric 0 4 * $metric;
    min-height: calc(100vh - 6em);
    display: flex;
    align-items: center;
    overflow-x: hidden;
    color: $white;
    // background-color: $purple;
    .cup {
      position: absolute;
      z-index: -1;
      height: $metric * 20;
      transform: translateX(-20rem) rotate(-20deg);
    }
    .cup2 {
      position: absolute;
      z-index: -1;
      height: $metric * 20;
      transform: translateX(5rem) translateY(2rem) rotate(20deg);
    }

    .svg {
      height: $font_size * 1.5;
      color: $white;
    }
    div {
      margin: auto;
    }
    h1 {
      margin: 2 * $metric $metric 4 * $metric;
      font-size: 1.5 * $font_size;
    }

    .card {
      background-color: $white;
      color: $greyText;
      border-radius: $metric * 4;
      padding: $metric * 4;
      margin: 4 * $metric auto;
      width: fit-content;
      border: solid $border_size $darkgrey;
      z-index: 2;
      width: 24rem;
      p {
        color: $greyText;
        margin-top: 4 * $metric;
      }
      h3 {
        color: $purple;
        margin-top: 0;
        margin-bottom: 1 * $metric;
        font-size: $font_size * 2.25;
      }

      .price {
        font-size: 4 * $font_size;
        font-family: "OpenSansB";
        span {
          font-size: $font_size * 1.5;
        }
      }
      button {
        font-size: $font_size * 1.25;
      }
    }
  }

  .billTypeSelector {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto;
    width: fit-content;
    margin: $metric * 1 auto;
    max-width: 100%;
    span {
      width: $metric * 20;
      text-align: center;
    }
    .left {
      text-align: right;
      padding-right: $metric;
    }
    .right {
      text-align: left;
      padding-left: $metric;
    }
  }

  .plans {
    margin: 2 * $metric 0;
    margin-bottom: $metric;

    .container {
      display: inline-block;
      margin: $metric;
      padding: $metric * 2;
      border: solid $border_size $darkgrey;
      border-radius: $metric * 2;
      width: $metric * 20;
      background-color: $white;
      color: $greyText;

      p {
        margin: 0;
      }

      .name {
        font-family: "OpenSansB";
        margin-bottom: $metric/2;
      }

      .size {
        font-family: "OpenSansB";
        font-size: $font_size * 1.2;
      }
      .users {
      }
      .price {
        font-family: "OpenSansB";
        color: $purple;
        font-size: $font_size * 3;
        line-height: $font_size * 3;
        margin: $metric * 2 0;
        span {
          font-size: $font_size * 1;
        }
      }
      .priceC {
        font-family: "OpenSansB";
        color: $purple;
        font-size: $font_size * 2;
        line-height: $font_size * 2;
        margin: $metric * 2 0;
        height: $font_size * 3;
      }
      .billedC {
        margin-top: $metric * 5.5;
      }

      .billed {
        margin-top: $metric * 4;
      }
    }
  }

  .features {
    margin-top: 0;
    background-color: $purple;
    padding: $metric * 4 $metric;
    padding-bottom: 12 * $metric;
    color: $white;
    h1 {
      font-size: 2 * $font_size;
      margin-bottom: 4 * $metric;
    }

    .list {
      .feature {
        margin: $metric/2 auto;
        padding: $metric * 2 $metric * 2;
        max-width: 80 * $metric;
        display: flex;
        flex-direction: row;
        align-items: center;
        // background-color: $white;
        // color: $greyText;
        // border-radius: $metric * 2;
        font-size: 1.25 * $font_size;
        text-align: left;
        .emoji {
          // width: $metric*4;
          svg {
            color: $white;
            height: $font_size * 1.25;
            margin-right: $metric/2;
          }
        }
        .des {
          flex: 1;
        }
      }
      .num0 {
        background-color: #ffffff25;
      }
      .num1 {
        background-color: #ffffff0e;
      }
    }
  }
}

.aboutus {
  color: $greyText;
  background-color: $white;
  // padding: 2*$metric;
  padding-top: 7.5 * $metric;
  h1 {
    margin: 0;
    margin-bottom: 2 * $metric;
  }

  .mission {
    background-color: $white;
    padding: 8 * $metric $metric 12 * $metric;
    margin: 0;
    color: $greyText;
    img {
      height: 15 * $metric;
      margin-bottom: $metric * 4;
    }
    h1 {
      color: $purple;
    }
    h3 {
      font-size: 1.5 * $font_size;
    }
  }

  .founders {
    background-color: $purple;
    padding: 8 * $metric $metric;
    color: $white;

    .founder {
      max-width: 30 * $metric;
      margin: $metric 2 * $metric;
      display: inline-block;
      img {
        width: 22 * $metric;
        height: 22 * $metric;
        object-fit: cover;
        border-radius: 50%;
        border: $metric $white solid;
      }
      p {
        margin: $metric;
      }
    }
  }

  .story {
    padding: 4 * $metric 2 * $metric;
    max-width: 75 * $metric;
    margin: 4 * $metric auto;
    font-weight: bold;
    text-align: left;

    h1 {
      text-align: center;
    }

    .logos {
      display: flex;
      flex-direction: row;
      align-items: center;
      max-width: 30 * $metric;
      margin: auto;
      margin-top: 4 * $metric;
      img {
        width: 40%;
        height: $metric * 8;
        object-fit: contain;
        object-position: center;
        margin: 0 $metric/2;
      }
      svg {
        color: $greyText !important;
        width: 20%;
        margin: 0 $metric/2;
        object-fit: contain;
        object-position: center;
      }
    }

    h1 {
      margin-bottom: $metric * 4;
      color: $purple;
    }
  }

  .tryNow {
    background-color: $purple;
    color: $white;
    padding: 8 * $metric $metric;
    button {
      margin: 4 * $metric auto $metric;
      font-size: 1.5 * $font_size;
    }
    p {
      margin: 0 auto;
    }
  }
}

.championImg {
  border: none !important;
  background-color: transparent !important;
}

.slackLanding {
  padding-top: 7 * $metric;

  .learnmore {
    padding: 10 * $metric $metric;
    button {
      margin: auto;
      margin-top: 3 * $metric;
      font-size: 1.25 * $font_size;
    }
  }
  .topS {
    padding: 15 * $metric $metric;
    // background-color: $white;
    // color: $black;
    button {
      margin: $metric * 4 auto 0;
  
      
      font-size: 1.2 * $font_size;
      img {
        height: 1.2 * $font_size !important;
      }
    }

    .topLogo {
      background-color: $white;

      padding: $metric * 2;
      border-radius: 6 * $metric;
      align-items: center;
      width: min-content;
      margin: auto;
      margin-bottom: $metric * 2;
      display: flex;
      flex-direction: row;
      align-items: center;
      h1 {
        margin: 1 * $metric;
        color: $purple;
      }
      img {
        height: $metric * 4;
        padding: $metric;
        // background-color: $white;
      }
      div {
        display: flex;
        flex-direction: row;
        align-items: center;
        h1 {
          margin: 0;
          color: $black;
          // margin-left: $metric;
        }
      }
    }
  }
  .afterTop {
    background-color: $white;
    color: $black;
    padding: 10 * $metric $metric;
    h2 {
      margin-bottom: 4 * $metric;
      font-size: 1.5 * $font_size;
    }
    button {
      background-color: $purple;
      color: $white;
      margin: auto;
      font-size: 1.25 * $font_size;
    }
    .instruction {
      // width: 54 * $metric;
      // max-width: 80%;
      // padding: 4 * $metric 2*$metric;
      // border: $border_size $darkgrey solid;
      // margin: $metric*2;
      // border-radius: 2*$metric;
      // display: inline-block;
      // height: 55*$metric;
      // max-height: 80%;
      // vertical-align: top;
      h3 {
        color: $purple;
      }

      .instructionsImg {
        width: 50 * $metric;
        max-width: 90%;
        margin-bottom: 4 * $metric;
        border-radius: $metric * 2;
      }
    }
  }
}

.help {
  .topH {
    padding: 9.5 * $metric $metric;
    padding-top: 15.5 * $metric;

    .topC {
      background-color: $white;
      width: 55 * $metric;
      max-width: 80%;
      color: $black;
      margin: 3 * $metric auto;
      padding: 3 * $metric;
      border-radius: 3 * $metric;
      box-shadow: 0 $border_size $black;

      button,
      input {
        margin: $metric * 2 auto;
        font-size: $font_size;
      }

      input,
      textarea {
        width: 70%;
      }
      button {
        background-color: $purple;
        color: $white;
      }
    }
  }

  .learnmore {
    padding: 10 * $metric $metric;
    button {
      margin: auto;
      margin-top: 3 * $metric;
      font-size: 1.25 * $font_size;
    }
  }

  .faqs {
    background-color: $white;
    color: $black;
    padding: 9.5 * $metric $metric;
    padding-top: 5.5 * $metric;

    .faq {
      background-color: #ffffff42;
      background-color: $purple;
      padding: 2 * $metric;
      border-radius: 3 * $metric;
      width: 65 * $metric;
      max-width: 80%;
      margin: 2 * $metric auto;
      color: $white !important;
      p {
        margin: 0;
        margin-bottom: $metric;
      }
      h3 {
        margin-bottom: 2 * $metric;
        background-color: $white;
        color: $purple;
        padding: 2 * $metric;
        border-radius: 2 * $metric;
      }
    }
  }
}

.red {
  color: $red;
}

.contactUsModal {
  padding: 2 * $metric 4 * $metric;
  color: $black;
  text-align: center;

  button,
  textarea,
  input {
    margin: $metric;
    font-size: $font_size;
    display: inline-block;
  }

  .submit {
    background-color: $purple;
    color: $white;
    margin-top: $metric * 3;
  }
  .cancel {
    background-color: $red;
    color: $white;
  }

  .inputHead {
    margin: 0;
    margin-top: 2 * $metric;
    font-size: 0.9 * $font_size;
  }
}

@media only screen and (max-width: 800px) {
  .landing .game span {
    font-size: $font_size * 1.2 !important;
  }

  .burgerMenu {
    position: fixed;
    display: block;
    right: 0;
    top: $font_size * 3.5;
    transform: translatex(100%);
    background-color: $white;
    border-bottom-left-radius: 2 * $metric;
    padding: $metric * 2;
    padding-top: 0;
    text-align: right;
    transition: all 0.1s linear;
    border: $darkgrey $border_size solid;
    border-right: 0;
    border-top: 0;
    z-index: 1000;

    a {
      color: black;
      margin: 2 * $metric;
      font-size: 1.25 * $font_size;
      line-height: 2.5 * $font_size;
      cursor: pointer;
    }

    button {
      background-color: $purple;
      color: $white;
      // font-weight: bold;
      cursor: pointer;
      // padding: $metric/1.5 *1.5$metric;
      // padding-right: $metric * 1.5;
      margin-top: $metric;
      font-size: 1.25 * $font_size;
      margin-left: auto;
      img {
        background-color: $white;
        padding: $metric/2;
        border-radius: $font_size * 2;
        margin-right: $metric;
      }
    }
  }
  .hamburger-react {
    display: block;
  }
  .header {
    .right {
      display: none !important;
    }
  }

  .openfalse {
    transform: translatex(100%);
  }
  .opentrue {
    transform: translatex(0);
  }

  .featureContainer {
    width: calc(100% - 6rem) !important;
    height: min-content !important;
  }

  .feedback {
    .container {
      max-width: 45 * $metric !important;
      width: calc(100vw - 8rem) !important;
    }
  }

  .game {
    width: calc(100vw - 4rem) !important;
    max-width: $metric * 50;
  }

  .top {
    padding-top: 7.5 * $metric;
    // padding-bottom: 8 * $metric;
    background-color: $white;
    height: calc(100vh - 3.5rem);
    min-height: 80 * $metric;
    display: flex;
    align-items: center;
    flex-direction: row;
    img {
      height: $metric * 20;
      width: auto;
      margin-bottom: 0;
    }
    .mid {
      margin: auto;
    }

    h1 {
      font-size: 2 * $font_size !important;
      // font-weight: bolder;
      padding: 2 * $metric $metric;
      // padding-top: 15 * $metric;
      // background-color: $white;
      color: $purple;
    }
    h2 {
      color: $greyText;
      font-size: $font_size * 1.2 !important;
      // background-color: $white;
    }
    p {
      font-size: 0.9 * $font_size !important;
      white-space: nowrap;
    }
    button {
      font-size: $font_size !important;
    }
  }

  .topP {
    // min-height: 65 * $metric !important;
    // overflow-x: hidden !important;
    .cup {
      height: $metric * 15 !important;
      transform: translateX(-14.5rem) rotate(-20deg) !important;
    }
    .cup2 {
      display: none;
    }
    .card {
      width: 16rem !important;
      h3 {
        font-size: 3 * $metric !important;
      }

      .price {
        font-size: 3 * $font_size !important;
        font-family: "OpenSansB";
        span {
          font-size: $font_size * 1.5;
        }
      }
    }
  }
}
